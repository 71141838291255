// @flow
import { decorate, observable, action } from 'mobx';

class NotificationStore {
  notifications = [];

  persistentNotifications = [];

  createPersistentNotification(label, actionName) {
    this.persistentNotifications.push({
      type: 'action',
      actionName,
      label,
    });
  }

  createNotification = (label, severity = 'info', timeout = 5000) => {
    this.notifications = []; // clean previous notifications

    this.notifications.push(
      {
        type: 'string',
        label,
        severity,
      },
    );

    setTimeout(() => {
      this.notifications = this.notifications
        .filter((notification) => label !== notification.label);
    }, timeout);
  }

  clearNotification = (label) => {
    this.notifications = this.notifications.filter((notification) => label !== notification.label);
  }
}

decorate(NotificationStore, {
  notifications: observable,
  persistentNotifications: observable,
  clearNotification: action,
});

export default new NotificationStore();
