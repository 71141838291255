// @flow
import {
  decorate,
  observable,
  action,
  computed,
} from 'mobx';
import _ from 'lodash';

import Api from '../../api';
import ScreenStore from '../screen/ScreenStore';
import config from '../../config';

const isProd = config.application === 'bright-prod';

class MomentStore {
  moments = []

  totalCount = 0

  creatingMoment = false;

  selectedMoment = null;

  getMoments = async (queryParams) => {
    const { data } = await Api.getMoments(queryParams);

    if (data) {
      this.totalCount = data.totalCount;

      this.moments = data.moments.map((moment) => {
        if (isProd && moment.extra.audioFile) {
          moment.extra.audioFile.url = moment.extra.audioFile.url
            .replace('app', 'admin').replace('app.learnwithbright.com', 'admin.learnwithbright.com');
        } else if (!isProd && moment.extra.audioFile) {
          moment.extra.audioFile.url = moment.extra.audioFile.url
            .replace('app-dev', 'admin-dev').replace('app.learnwithbright.com', 'admin.learnwithbright.com');
        }

        return moment;
      });
    }

    return this.moments;
  }

  async getMoment(momentId) {
    const { data } = await Api.getMoment(momentId);

    if (data) {
      this.selectedMoment = data;
      if (isProd && data.extra.audioFile) {
        data.extra.audioFile.url = data.extra.audioFile.url.replace('app', 'admin').replace('app.learnwithbright.com', 'admin.learnwithbright.com');
      } else if (!isProd && data.extra.audioFile) {
        data.extra.audioFile.url = data.extra.audioFile.url.replace('app-dev', 'admin-dev').replace('app.learnwithbright.com', 'admin.learnwithbright.com');
      }
    }

    return data;
  }

  clearSelectedMoment() {
    this.selectedMoment = null;
  }

  // eslint-disable-next-line class-methods-use-this
  sanitizeSkillConditions(moment) {
    if (moment?.skillsAI?.skills?.length) {
      for (let i = 0; i < moment?.skillsAI?.skills.length; i += 1) {
        const skill = moment?.skillsAI?.skills[i];
        const { ratingRules } = skill;
        if (ratingRules?.length) {
          for (let j = 0; j < ratingRules.length; j += 1) {
            const ratingRule = ratingRules[j];
            const { conditions } = ratingRule;
            if (conditions?.length) {
              for (let k = 0; k < conditions.length; k += 1) {
                const condition = conditions[k];
                const { expression, subject } = condition;
                if (['duration'].includes(subject)) {
                  condition.terms = [];
                  delete condition.commonError;
                  delete condition.intentSamples;
                  delete condition.intentExplanation;
                  delete condition.commonErrorForIntent;
                } else if (['phrase', '100', '90', '80'].includes(expression)) {
                  delete condition.duration;
                  delete condition.intentSamples;
                  delete condition.intentExplanation;
                  delete condition.commonErrorForIntent;
                } else {
                  delete condition.duration;
                  delete condition.terms;
                  delete condition.commonError;
                }
              }
            }
          }
        }
      }
    }
  }

  async updateMoment(moment) {
    this.creatingMoment = true;
    const audioFile = _.get(moment, 'extra.audioFile');

    if (isProd && audioFile) {
      moment.extra.audioFile.url = moment.extra.audioFile.url.replace('admin', 'app').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
    } else if (!isProd && audioFile) {
      moment.extra.audioFile.url = moment.extra.audioFile.url.replace('app-dev', 'admin-dev').replace('admin.learnwithbright.com', 'app.learnwithbright.com');
    }
    this.sanitizeSkillConditions(moment);
    const { data } = await Api.updateMoment(moment);
    this.selectedMoment = data;

    await this.getMoments();
    this.creatingMoment = false;

    return data;
  }

  async createMoment(moment) {
    this.creatingMoment = true;
    this.sanitizeSkillConditions(moment);
    const { data } = await Api.createMoment(moment);
    this.creatingMoment = false;

    await this.getMoments();

    return data;
  }

  addBreakpoint(breakpoint) {
    this.selectedMoment.extra.breakpoints.push(breakpoint);
  }

  removeBreakpoint(breakpoint) {
    this.selectedMoment.extra.breakpoints = this.selectedMoment.extra.breakpoints
      .filter(({ uuid }) => uuid !== breakpoint.uuid);
  }

  async deleteMoment(momentId) {
    const { data } = await Api.deleteMoment(momentId);
    this.moments = [];
    await this.getMoments();

    return data;
  }

  deleteMoments = async (momentIds) => {
    await Api.deleteMoments(momentIds);
    await this.getMoments();
  }

  // This getter is deprecated and can be removed after a while
  get momentScreens() {
    const moment = this.selectedMoment;
    const { screenGroups, screens: allScreens } = ScreenStore;

    const screens = [];

    if (!moment || moment.type !== 'System') {
      return screens;
    }

    const findChildrenScreens = (screen) => {
      const jumps = _.get(screen, 'layers', []).filter((s) => s.type === 'jump' && _.get(s, 'extra.meta.jumpTo') != null);

      jumps.forEach(({ extra }) => {
        const screenId = extra.meta.jumpTo;

        const found = _.find(allScreens, ({ id }) => id === screenId);
        if (found) {
          const exists = _.find(screens, ({ id }) => id === found.id);
          if (exists) {
            return;
          }

          screens.push(found);
          findChildrenScreens(found);
        }
      });
    };

    const screenGroup = screenGroups.find(({ id }) => id === moment.canvasId);
    const startScreenId = _.get(screenGroup, 'firstScreenInfo.id');
    const startScreen = _.find(allScreens, ({ id }) => id === startScreenId);

    if (startScreen) {
      screens.push(startScreen);
      findChildrenScreens(startScreen);
    }

    return screens;
  }

  async validateBulkCreateMoments(momentsListToBulkCreate, simulationId) {
    const { data } = await Api.validateBulkCreateMoments(momentsListToBulkCreate, simulationId);

    return data;
  }

  async bulkCreateMoments(momentsListToBulkCreate, simulationId) {
    const { data } = await Api.bulkCreateMoments(momentsListToBulkCreate, simulationId);

    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  bulkTagMoments = async ({ selectedTeamIds, momentIds }) => {
    try {
      await Api.bulkTagMoments({
        selectedTeamIds: selectedTeamIds.map(({ id }) => id),
        momentIds,
      });

      return true;
    } catch (e) {
      throw new Error(e);
    }
  }
}

decorate(MomentStore, {
  moments: observable,
  selectedMoment: observable,
  getMoments: action,
  deleteMoment: action,
  deleteMoments: action,
  addBreakpoint: action,
  momentScreens: computed,
  clearSelectedMoment: action,
  validateBulkCreateMoments: action,
  bulkCreateUsers: action,
});

export default new MomentStore();
