// @flow
import { action, decorate, observable } from 'mobx';
import Api from '../../api';

class LessonBuilderStore {
  isBuilderLoading = false;

  currenLessonId = null;

  currentModuleId = null;

  getLessonBuilder = async (lessonId) => {
    try {
      this.isBuilderLoading = true;

      const { data } = await Api.getLessonBuilder(lessonId);

      return data;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  upsertLessonBuilder = async (lessonBuilder) => {
    try {
      this.isBuilderLoading = true;

      const { data } = await Api.upsertLessonBuilder(lessonBuilder);

      return data;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteLesson = async (lessonId) => {
    try {
      this.isBuilderLoading = true;
      const res = await Api.deleteLessonBuilder(lessonId);

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteModule = async (moduleId) => {
    try {
      this.isBuilderLoading = true;
      const res = await Api.deleteModule(this.currenLessonId, moduleId);

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteSection = async (sectionId) => {
    try {
      this.isBuilderLoading = true;
      const res = await Api.deleteSection(
        this.currenLessonId,
        this.currentModuleId,
        sectionId,
      );

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteCard = async (cardId) => {
    try {
      this.isBuilderLoading = true;
      const res = await Api.deleteCard(cardId);

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteQuestion = async (questionId) => {
    try {
      this.isBuilderLoading = true;

      const res = await Api.deleteQuestion(this.currenLessonId, this.currentModuleId, questionId);

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteChoice = async (questionId, choiceId) => {
    try {
      this.isBuilderLoading = true;

      const res = await Api.deleteChoice(
        this.currenLessonId,
        this.currentModuleId,
        questionId,
        choiceId,
      );

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  setLessonData = async ({ lessonId, moduleId }) => {
    this.currenLessonId = lessonId || this.currenLessonId;
    this.currentModuleId = moduleId || this.currentModuleId;
  };
}

decorate(LessonBuilderStore, {
  isBuilderLoading: observable,
  getLessonBuilder: action,
  upsertLessonBuilder: action,
  deleteLesson: action,
  deleteModule: action,
  deleteSection: action,
  deleteCard: action,
  deleteQuestion: action,
  deleteChoice: action,
  setLessonData: action,
});

export default new LessonBuilderStore();
