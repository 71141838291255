/* eslint-disable import/no-cycle */
import {
  action,
  decorate,
  computed,
  observable,
} from 'mobx';
import { extend } from 'lodash';

import Api from '../../api';
import ScreenStore from '../screen/ScreenStore';

class CanvasStore {
  constructor() {
    this.screenGroups = [];
    this.currentCanvas = null;
    this.totalCount = 0;
    this.fetchCanvaState = null;
  }

  getCanvases = async (queryParams) => {
    const { data } = await Api.getCanvases(queryParams);

    if (data) {
      this.screenGroups = data.canvases;
      this.totalCount = data.totalCount;
    }
    return this.screenGroups;
  }

  async getCanvas(canvasId, flattenTeamIds = false) {
    try {
      const { data } = await Api.getCanvas(canvasId);

      if (flattenTeamIds) {
        data.teams = (data.teams || []).map(({ id }) => id);
      }

      this.currentCanvas = data;

      return this.currentCanvas;
    } catch (e) {
      this.fetchCanvaState = e.response.status;
    }
  }

  clearCurrentCanvas() {
    this.currentCanvas = null;
  }

  async createCanvas(canvas) {
    const { data } = await Api.createCanvas(canvas);
    this.currentCanvas = data;
    return data;
  }

  async updateCanvas(canvas) {
    const { data: updatedCanvas } = await Api.updateCanvas(canvas);

    this.currentCanvas = updatedCanvas;

    return updatedCanvas;
  }

  // eslint-disable-next-line class-methods-use-this
  async bulkTagCanvases({ selectedTeamIds, canvasIds }) {
    try {
      await Api.bulkTagCanvases({
        selectedTeamIds: selectedTeamIds.map(({ id }) => id),
        canvasIds,
      });

      return true;
    } catch (e) {
      throw new Error(e);
    }
  }

  async cloneCanvas() {
    const clonedCanvas = extend(this.currentCanvas, { name: `${this.currentCanvas.name} (Clone)` });
    delete clonedCanvas.id;
    const { data } = await Api.cloneCanvas(clonedCanvas);
    return data;
  }

  async deleteCanvas(canvasId) {
    await Api.deleteCanvas(canvasId);
    ScreenStore.updateScreens([]);
    await this.getCanvases();
  }

  async deleteCanvases(canvasIds) {
    await Api.deleteCanvases(canvasIds);
    ScreenStore.updateScreens([]);
    await this.getCanvases();
  }

  clearCanvases = () => {
    this.canvases = [];
    this.totalCount = 0;
  }
}

decorate(CanvasStore, {
  screenGroups: observable,
  currentCanvas: observable,
  getCanvases: action,
  getCanvas: action,
  clearCurrentCanvas: action,
  createCanvas: action,
  updateCanvas: action,
  bulkTagCanvases: action,
  cloneCanvas: action,
  deleteCanvas: action,
  deleteCanvases: action,
  clearResources: action,
});

export default new CanvasStore();
